import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Wrapper from "../components/Wrapper"
import styled from "styled-components"
import CasePreview from "../components/CasePreview"
import PrimaryButton from "../components/PrimaryButton"
import SecondaryButton from "../components/SecondaryButton"
import Estimate from "../components/Estimate"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LandingVideo from "../assets/main.mp4"
import { getImage } from "gatsby-plugin-image"
import classNames from "classnames"
import Sections from "../components/Sections"

const LandingText = styled.div`
    padding-top: 75px;
    padding-bottom: 50px;
    @media (min-width: ${props => props.theme.size.large}) {
        padding: 160px 0 50px 40px;
    }
    p {
        font-size: 1.2em;
        max-width: 600px;
        padding: 0 0 20px 0;
    }
    h1 span {
        color: ${props => props.theme.color.hybl};
    }
`

const IndexPage = ({ data }) => {
    const [open, setOpen] = React.useState(false)

    return (
        <Layout>
            <HelmetDatoCms>
                <body
                    className={classNames("text-gray-800", {
                        "no-scroll": open,
                    })}
                />
                <title>
                    {data.datoCmsLanding.siteseo &&
                        data.datoCmsLanding.siteseo.title}
                </title>
                <meta
                    name="description"
                    content={
                        data.datoCmsLanding.siteseo &&
                        data.datoCmsLanding.siteseo.description
                    }
                />
            </HelmetDatoCms>
            <Estimate open={open} setOpen={setOpen} />
            <div className="relative z-0">
                <Wrapper className="relative z-20 md:h-screen max-h-140 2xl:max-h-150">
                    <LandingText>
                        <h1
                            className="text-6xl lg:text-7xl font-extrabold mt-4 leading-none"
                            dangerouslySetInnerHTML={{
                                __html: data.datoCmsLanding.title,
                            }}
                        />
                        <p>{data.datoCmsLanding.subtitle}</p>
                        <div className="flex">
                            <PrimaryButton
                                className="mr-4"
                                to={data.datoCmsLanding.primaryButtonLink}
                                primaryButtonText={
                                    data.datoCmsLanding.primaryButtonText
                                }
                            />
                            <SecondaryButton
                                onClick={e => {
                                    e.preventDefault()
                                    setOpen(true)
                                }}
                                secondaryButtonText={
                                    data.datoCmsLanding.secondaryButtonText
                                }
                            />
                        </div>
                    </LandingText>
                </Wrapper>
                <div className="hidden md:flex absolute z-0 top-0 aspect-video right-0 items-center justify-end">
                    <div className="w-4/5 2xl:w-full relative aspect-square">
                        <div className="absolute z-10 h-full w-full bg-gradient-to-r from-white via-transparent"></div>
                        <div className="absolute z-10 h-full w-full bg-gradient-to-t from-white"></div>
                        <video
                            className="w-full opacity-90 aspect-square object-cover"
                            loop
                            autoPlay
                            muted
                        >
                            <source src={LandingVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
            <Sections
                className="lg:relative -top-12 -mb-12"
                sections={data.datoCmsLanding.sections}
            />
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query MyQuery {
        datoCmsLanding {
            id
            title
            subtitle
            primaryButtonText
            primaryButtonLink
            secondaryButtonText
            secondaryButtonLink
            siteseo {
                title
                description
            }
            sections {
                ... on DatoCmsImage {
                    ...imageFields
                }
                ... on DatoCmsTagRow {
                    ...tagRowFields
                }
                ... on DatoCmsLogoBanner {
                    ...logoBannerFields
                }
                ... on DatoCmsContact {
                    ...contactFields
                }
                ... on DatoCmsServiceRow {
                    ...serviceRowFields
                }
                ... on DatoCmsCaseList {
                    ...caseListFields
                }
            }
        }

        allDatoCmsWork(sort: { fields: position, order: ASC }) {
            edges {
                node {
                    ...caseFields
                }
            }
        }
    }
`
