import * as React from "react"
import Wrapper from "./Wrapper"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import Checkmark from "../assets/svg/checkmark.inline.svg"
import classNames from "classnames"

const Close = styled.div`
    position: fixed;
    z-index: 11;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    margin: 10px 10px 0;
    @media (min-width: ${props => props.theme.size.large}) {
        margin: 20px 20px 0;
    }
    transition: 0.2s ease-out;
    opacity: ${props => (props.open ? "1" : "0")};
    background-color: ${props => props.theme.color.hyblLight};
    box-shadow: ${props => props.theme.shadow.soft1};
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: ${props => (props.open ? "normal" : "none")};
    cursor: pointer;
    &:before,
    &:after {
        content: "";
        display: block;
        width: 3px;
        height: 60%;
        border-radius: 100px;
        background-color: ${props => props.theme.color.darkGrey};
        position: absolute;
        transition: 0.2s ease-out;
    }
    &:hover {
        &:before,
        &:after {
            background-color: black;
        }
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`

const StepBreadcrumbs = styled.div`
    h5 {
        text-transform: uppercase;
        margin-bottom: 5px;
    }
    .progress-bar {
        width: 100%;
        height: 3px;
        background-color: ${props => props.theme.color.grey};
        & > div {
            transition: 0.2s ease-out;
            background-color: ${props => props.theme.color.hybl};
            width: ${props => "calc((100%/3) * " + props.progress + ")"};
            height: 100%;
        }
    }
`
const Controls = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
    button {
        border: none;
        outline: none;
        border-radius: 0;
        margin: 10px;
        padding: 15px 25px;
        display: block;
        cursor: pointer;
        text-transform: uppercase;
        font-family: "montserrat", sans-serif;
        font-weight: 600;
        font-size: 0.68rem;
        letter-spacing: 1px;
        transition: 0.2s ease-out;
        transform: scale(1);
        &[disabled] {
            visibility: hidden;
            transform: scale(0.95);
        }
        &.btn--primary {
            background-color: ${props => props.theme.color.hybl};
            color: white;
        }
    }
`

const Option = styled.label`
    display: flex;
    align-items: center;
    transition: all 0.2s ease-out;
    background-color: ${props => props.theme.color.grey};
    padding: 15px;
    margin-bottom: 15px;
    @media (min-width: ${props => props.theme.size.large}) {
        padding: 20px;
    }
    h4 {
        margin-top: 0;
        margin-bottom: 5px;
        @media (min-width: ${props => props.theme.size.large}) {
            font-size: 1.25em;
        }
    }
    p {
        margin: 0;
        font-size: 0.9em;
    }
    & > span {
        display: block;
        margin-left: 10px;
        @media (min-width: ${props => props.theme.size.large}) {
            margin-left: 20px;
        }
    }

    .checkbox {
        height: 25px;
        width: 25px;
        min-width: 25px;
        background-color: white;
        padding: 3px;
        box-sizing: border-box;
        svg {
            width: 100%;
            height: 100%;
            opacity: 0;
            transform: scale(0.7) rotate(10deg);
            transition: 0.1s ease-out;
        }
    }
    input {
        display: none;
        &:checked + .checkbox {
            svg {
                opacity: 1;
                transform: scale(1) rotate(0deg);
            }
        }
    }
    &:hover {
        background-color: ${props => props.theme.color.mediumGrey};
        cursor: pointer;
    }
`

const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    input {
        width: 100%;
        margin: 5px 0 25px;
        outline: none;
        border: none;
        border-bottom: 1px solid ${props => props.theme.color.mediumGrey};
        padding: 1em 0;
        font-family: "montserrat", "sans-serif";
        &::placeholder {
            color: transparent;
        }
        &:focus,
        &:not(:placeholder-shown) {
            outline: 0;
            box-shadow: 0;
            & + label {
                transform: translateY(-1.5em) scale(0.8);
                opacity: 0.8;
            }
        }
        & + label {
            transform-origin: 0 0;
            line-height: 1;
            font: inherit;
            padding: 1em 0;
            height: 1em;
            position: absolute;
            top: 0;
            left: 0;
            user-select: none;
            pointer-events: none;
            transition: 0.2s ease-out;
            color: ${props => props.theme.color.darkGrey};
        }
    }
`

const Estimate = props => {
    const [form, setForm] = React.useState({
        type: [],
        description: "",
        name: "",
        company: "",
        number: "",
        email: "",
    })
    const [step, setStep] = React.useState(1)
    const [loading, setLoading] = React.useState(false)

    const resetInputWarnings = () => {
        const inputWarnings = document.querySelectorAll(".form-input--missing")
        inputWarnings.forEach(warning => {
            if (warning)
                warning.classList.remove(
                    "form-input--missing",
                    "border",
                    "border-red-400"
                )
        })
    }

    const formIsValid = () => {
        let formIsValid = true
        resetInputWarnings()

        if (step === 2) {
            if (form.description === "") {
                console.log("description not added")
                document
                    .querySelector('[name="description"]')
                    .classList.add("form-input--missing border border-red-400")
                formIsValid = false
            }
        } else if (step === 3) {
            if (form.number === "") {
                console.log("phone number missing")
                document
                    .querySelector('[name="number"]')
                    .classList.add(
                        "form-input--missing",
                        "border",
                        "border-red-400"
                    )
                formIsValid = false
            }
            if (form.email === "") {
                console.log("email missing")
                document
                    .querySelector('[name="email"]')
                    .classList.add(
                        "form-input--missing",
                        "border",
                        "border-red-400"
                    )
                formIsValid = false
            }
            if (form.name === "") {
                console.log("name missing")
                document
                    .querySelector('[name="name"]')
                    .classList.add(
                        "form-input--missing",
                        "border",
                        "border-red-400"
                    )
                formIsValid = false
            }
        }

        return formIsValid
    }

    const prevStep = e => {
        e.preventDefault()
        setStep(step - 1)
    }

    const nextStep = e => {
        e.preventDefault()

        if (!formIsValid()) {
            return
        } else {
            setStep(step + 1)
        }
    }

    const validateForm = e => {
        e.preventDefault()
        if (formIsValid()) {
            sendForm()
        } else {
            alert("Udfyld venligst alle felter")
        }
    }

    const encodeData = data => {
        return Object.keys(data)
            .map(
                key =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(data[key])
            )
            .join("&")
    }

    const sendForm = async () => {
        try {
            setLoading(true)
            const response = await fetch(
                process.env.GATSBY_FORM_SUBMISSION_URL,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: encodeData(form),
                }
            )
            const body = await response.json()

            if (!response.ok) {
                throw new Error(body.message)
            } else {
                setLoading(false)
                console.log("Form successfully sent", form)
                props.setOpen(false)
            }
        } catch (error) {
            setLoading(false)
            alert(
                "Vi beklager, der skete en fejl. Send os en mail på hello@hybl.dk. Fejlen er: ",
                error.message
            )
        }
    }

    const updateForm = e => {
        const target = e.target
        const name = target.name
        const value = target.value

        if (target.type === "checkbox") {
            if (target.checked) {
                const newArray = [...form[name], value]
                setForm({ ...form, [name]: newArray })
            } else {
                const array = [...form[name]]
                const index = array.indexOf(value)
                if (index !== -1) {
                    array.splice(index, 1)
                    setForm({ ...form, [name]: array })
                }
            }
        } else {
            setForm({ ...form, [name]: value })
        }
    }
    return (
        <StaticQuery
            query={graphql`
                query EstimateQuery {
                    datoCmsEstimate {
                        title
                        description
                        step1Title
                        step1Description
                        step1Options {
                            title
                            description
                            icon {
                                fixed(width: 50) {
                                    ...GatsbyDatoCmsFixed_noBase64
                                }
                            }
                        }

                        step2Title
                        step2Description
                        step2Placeholder

                        step3Title
                        step3Description
                        step3Fields {
                            name
                            inputType
                            label
                        }
                    }
                }
            `}
            render={data => (
                <div className="relative">
                    <Close
                        open={props.open}
                        onClick={() => props.setOpen(false)}
                    />
                    <div
                        className={classNames(
                            "fixed lg:overflow-hidden z-10 inset-0 min-h-screen flex flex-col h-full overflow-auto scrollbar-none md:flex-row",
                            { "pointer-events-none": !props.open }
                        )}
                        open={props.open}
                    >
                        <div
                            className={classNames(
                                "w-full md:w-1/2 lg:justify-end lg:pr-6 transition-all bg-hybl py-12 md:h-full flex justify-center items-center",
                                { "-translate-x-full opacity-0": !props.open }
                            )}
                        >
                            <div className="text-white md:pr-24 w-full px-4 md:w-auto">
                                <h1 className="leading-none md:mb-6 mb-2 font-extrabold text-3xl md:text-5xl">
                                    {data.datoCmsEstimate.title}
                                </h1>
                                <div
                                    className="text-white"
                                    dangerouslySetInnerHTML={{
                                        __html: data.datoCmsEstimate
                                            .description,
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={classNames(
                                "transition-all scrollbar-none bg-white flex-grow py-20 items-start overflow-y-auto w-full md:w-1/2 md:h-full md:flex md:justify-start lg:pl-6",
                                { "translate-x-full opacity-0": !props.open }
                            )}
                        >
                            <Wrapper className="mx-0">
                                <StepBreadcrumbs progress={step}>
                                    <h5 className="font-bold">
                                        Trin {step} / 3
                                    </h5>
                                    <div className="progress-bar">
                                        <div />
                                    </div>
                                </StepBreadcrumbs>
                                <form
                                    name="Estimate"
                                    id="estimateForm"
                                    data-netlify="true"
                                    method="POST"
                                    action="/"
                                >
                                    <input
                                        type="hidden"
                                        name="form-name"
                                        value="Estimate"
                                    />
                                    <input type="hidden" name="type" />
                                    <input type="hidden" name="description" />
                                    {data.datoCmsEstimate.step3Fields.map(
                                        (field, idx) => (
                                            <div key={idx}>
                                                <input
                                                    type="hidden"
                                                    name={field.name}
                                                />
                                            </div>
                                        )
                                    )}
                                    {step === 1 && (
                                        <div className="form-group">
                                            <h2 className="mt-2 font-extrabold lg:text-4xl">
                                                {
                                                    data.datoCmsEstimate
                                                        .step1Title
                                                }
                                            </h2>
                                            <div
                                                className="mb-4"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.datoCmsEstimate
                                                        .step1Description,
                                                }}
                                            />
                                            {data.datoCmsEstimate.step1Options.map(
                                                (option, index) => (
                                                    <Option
                                                        key={
                                                            option.id +
                                                            "" +
                                                            index
                                                        }
                                                        htmlFor={
                                                            "option-" + index
                                                        }
                                                        icon={option.icon.fixed}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name="type"
                                                            id={
                                                                "option-" +
                                                                index
                                                            }
                                                            value={option.title}
                                                            checked={
                                                                form.type.includes(
                                                                    option.title
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={
                                                                updateForm
                                                            }
                                                        />
                                                        <div className="checkbox">
                                                            <Checkmark />
                                                        </div>
                                                        <span>
                                                            <h4>
                                                                {option.title}
                                                            </h4>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: option.description,
                                                                }}
                                                            />
                                                        </span>
                                                    </Option>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {step === 2 && (
                                        <div className="form-group">
                                            <h2 className="mt-2 font-extrabold lg:text-4xl">
                                                {
                                                    data.datoCmsEstimate
                                                        .step2Title
                                                }
                                            </h2>
                                            <div
                                                className="mb-4"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.datoCmsEstimate
                                                        .step2Description,
                                                }}
                                            />
                                            <textarea
                                                className="border p-6 outline-none font-sans w-full min-h-[200px] resize-y box-border border-gray-300"
                                                id="estimate-description"
                                                placeholder={
                                                    data.datoCmsEstimate
                                                        .step2Placeholder
                                                }
                                                name="description"
                                                onChange={updateForm}
                                                defaultValue={form.description}
                                            ></textarea>
                                        </div>
                                    )}
                                    {step === 3 && (
                                        <div className="form-group">
                                            <h2 className="mt-2 font-extrabold lg:text-4xl">
                                                {
                                                    data.datoCmsEstimate
                                                        .step3Title
                                                }
                                            </h2>
                                            <div
                                                className="mb-4"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.datoCmsEstimate
                                                        .step3Description,
                                                }}
                                            />
                                            {data.datoCmsEstimate.step3Fields.map(
                                                (field, index) => (
                                                    <Field
                                                        key={`${field.id}--${index}`}
                                                    >
                                                        <input
                                                            className="py-2"
                                                            type={
                                                                field.inputType
                                                            }
                                                            placeholder={
                                                                field.label
                                                            }
                                                            id={
                                                                "field-" +
                                                                field.name
                                                            }
                                                            name={field.name}
                                                            onChange={
                                                                updateForm
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={
                                                                "field-" +
                                                                field.name
                                                            }
                                                        >
                                                            {field.label}
                                                        </label>
                                                    </Field>
                                                )
                                            )}
                                        </div>
                                    )}

                                    <Controls>
                                        <button
                                            onClick={e => prevStep(e)}
                                            disabled={step < 2}
                                        >
                                            Tilbage
                                        </button>
                                        {step < 3 && (
                                            <button
                                                onClick={e => nextStep(e)}
                                                className="btn btn--primary"
                                                disabled={step > 2}
                                            >
                                                Næste
                                            </button>
                                        )}
                                        {step === 3 && (
                                            <button
                                                onClick={e => validateForm(e)}
                                                type="submit"
                                                className={`btn btn--primary ${
                                                    loading
                                                        ? "animate-pulse"
                                                        : ""
                                                }`}
                                            >
                                                Send
                                            </button>
                                        )}
                                    </Controls>
                                </form>
                            </Wrapper>
                        </div>
                    </div>
                </div>
            )}
        />
    )
}

export default Estimate
